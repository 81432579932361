import React from 'react';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format, parse } from 'date-fns';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm:ss';

const parseDate = (date: string) => parse(date, DATE_FORMAT, refDate);
const formatDate = (date: Date) => format(date, DATE_FORMAT);

const parseTime = (date: string) => parse(date, TIME_FORMAT, refDate);
const formatTime = (date: Date) => format(date, TIME_FORMAT);

interface DateFieldProps {
  autoFocus?: boolean;
  label?: string;
  value: string;
  onChange: (newDate: string) => void;
}

type TimeFieldProps<TDate = Date> = DateFieldProps & {
  showSeconds?: boolean;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  props?: Partial<TimePickerProps<TDate>>;
};

const refDate = new Date();

export const DateField = ({
  autoFocus = false,
  label,
  value,
  onChange,
}: DateFieldProps) => {
  return (
    <DatePicker
      autoFocus={autoFocus}
      disableOpenPicker
      format="yyyy-MM-dd"
      label={label}
      value={parseDate(value)}
      onChange={(date, context) => {
        if (context.validationError) {
          console.warn(context.validationError);

          return;
        }
        // console.log('DatePicker onChange', date);

        const formatted = formatDate(date);
        // console.log('formatted', formatted);

        onChange(formatted);
      }}
    />
  );
};

export const TimeField = ({
  autoFocus = false,
  label,
  value,
  onChange,
  showSeconds = false,
  onBlur,
  props,
}: TimeFieldProps) => {
  return (
    <TimePicker
      autoFocus={autoFocus}
      disableOpenPicker
      label={label}
      format={showSeconds ? 'HH:mm:ss' : 'HH:mm'}
      value={parseTime(value)}
      onChange={(date: Date, context) => {
        if (context.validationError) {
          console.warn(context.validationError);

          return;
        }

        // console.log('TimePicker onChange', date);
        const formatted = formatTime(date);
        // console.log('formatted', formatted);

        // onChange(formatted);
      }}
      onAccept={(date) => {
        // console.log('TimePicker onAccept', date);
        const formatted = formatTime(date);
        // console.log('formatted', formatted);

        onChange(formatted);
      }}
      slotProps={{
        ...props?.slotProps,
        textField: {
          onBlur,
          ...props?.slotProps?.textField,
        },
      }}
    />
  );
};
