import React, { useState } from 'react';
import { useEffect } from 'react';

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { observer } from 'mobx-react';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from 'tss-react/mui';
import { useSearchParams } from 'react-router-dom';

import { styled } from '../../colors';
import {
  WhiteContainedButton,
  WhiteOutlinedButton,
} from '../../components/button';
import { StripeProvider } from '../../components/stripe/StripeProvider';
import { useMainStore } from '../../stores/useStore';
import { Header3, Header4 } from '../Dashboard/DashboardHeader';
import daysRemainingIcon from '../../assets/account-active.png';
import CoreIcon from '../../assets/1_Core_Transparent.png';
import ProIcon from '../../assets/2_Pro_Transparent.png';
import needMorePowerIcon from '../../assets/need_more_power.png';
import MainStore from '../../stores/Store';
import { serverUrl } from '../../config';

import { useBillingStore } from './BillingStore';
import { PaymentModal } from './PaymentModal';

const STRINGS = {
  DaysRemaining: 'days until next payment',
  NeedMorePower: 'Need more power?',
  PowerLastsUntil: 'Your Core powers will last until',
};

export const Billing = observer(function Billing() {
  const billingStore = useBillingStore();

  const [StripeLink, setStripeLink] = useState('');

  const store = useMainStore();
  const { classes, cx } = useStyles();

  const stripCoreLink = 'https://buy.stripe.com/dR6dSA7Mi4Sc8hicMN';
  const stripProLink = 'https://buy.stripe.com/00geWE8QmbgAbtufYY';
  const stripLoginLink =
    'https://billing.stripe.com/p/login/aEUdULdtcdBug2Q000';

  useEffect(() => {
    setStripeLink(stripLoginLink);

    fetch(serverUrl + '/stripe_customer_links', {
      // Enter your IP address here

      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: user_id }),
    }).then(async (response) => {
      const result = await response.json();

      console.log('result', result);

      if (result.url) {
        console.log('result.url', result.url);

        setStripeLink(result.url);
      }
    });
  }, []);

  if (!store.me) {
    return null;
  }

  // paymentExpires is a unix timestamp
  const expiryDate =
    store.me.paymentExpires && new Date(Number(store.me.paymentExpires) * 1000);

  const daysLeft =
    expiryDate && differenceInCalendarDays(expiryDate, new Date());
  let activeAccount = store.hasActivePaidAccount;

  if (activeAccount != 'CORE' && activeAccount != 'PRO') {
    activeAccount = null;
  }

  const handleClose = () => {
    billingStore.setShowingPaymentModal(false);
  };

  const user_id = MainStore.store.me.id;

  function openLink() {
    window.location.replace(StripeLink);
  }

  return (
    <StripeProvider>
      <div id="BillingContainer" className={cx(classes.root)}>
        {activeAccount == 'CORE' ? (
          <>
            <Header3>
              Account level: <b>Core</b>
            </Header3>
            <div className={cx(classes.titleSpace)}>
              <img src={CoreIcon} alt="" style={{ width: '150px' }} />
              <Header3>
                <Tooltip
                  title={`${
                    STRINGS.PowerLastsUntil
                  } ${expiryDate.toLocaleString()}`}
                >
                  <strong>{`${daysLeft} ${STRINGS.DaysRemaining}`}</strong>
                </Tooltip>
              </Header3>
              <Header4>
                Psst!!
                <br />
                Head over to Checkpoints.se to discover our Pro-package and it’s
                awesome features!
                <br />
                <br />
                And since you’ve got Core-days to spare, we’ll convert them to a
                discount when upgrading to Pro.
              </Header4>
            </div>

            <WhiteOutlinedButton
              onClick={() => {
                window.open('https://checkpoints.se#pricing_wrapper');
              }}
            >
              Compare levels
            </WhiteOutlinedButton>

            <WhiteContainedButton
              onClick={() => {
                window.location.replace(stripProLink);
                // billingStore.setShowingPaymentModal(true);
              }}
            >
              Start Pro Subscription
            </WhiteContainedButton>

            <WhiteOutlinedButton
              onClick={() => {
                openLink();
              }}
            >
              Manage subscription
            </WhiteOutlinedButton>
          </>
        ) : null}
        {activeAccount == 'PRO' ? (
          <>
            <Header3>
              Account level: <b>Pro</b>
            </Header3>

            <div className={cx(classes.titleSpace)}>
              <img src={ProIcon} alt="" style={{ width: '150px' }} />
              <Header3>
                <Tooltip
                  title={`${
                    STRINGS.PowerLastsUntil
                  } ${expiryDate.toLocaleString()}`}
                >
                  <>
                    Ongoing Pro Subscription
                    <br />
                    <strong>{`${daysLeft} ${STRINGS.DaysRemaining}`}</strong>
                  </>
                </Tooltip>
              </Header3>
              <Header4>
                Wehey!
                <br />
                Welcome to the Pro-club!
                <br />
                <br />
                This is the [currently] highest level on Checkpoints, but we’re
                working on releasing our Enterprise-package.
                <br />
                Stay tuned for even more awesome features!
              </Header4>
            </div>

            <WhiteOutlinedButton
              onClick={() => {
                openLink();
              }}
            >
              Manage subscription
            </WhiteOutlinedButton>
          </>
        ) : null}
        {activeAccount == null ? (
          <>
            <div className={cx(classes.titleSpace)}>
              <img
                src={needMorePowerIcon}
                alt=""
                className={cx(classes.icon)}
              />
              <Header3>
                <strong>{STRINGS.NeedMorePower}</strong>
              </Header3>
              <Header4>
                You are currently on the ”Subscriber” level. To get all features
                you need to upgrade yourself to ”Core” or ”Pro”.
              </Header4>
            </div>

            <WhiteOutlinedButton
              onClick={() => {
                window.open('https://checkpoints.se#pricing_wrapper');
              }}
            >
              Compare levels
            </WhiteOutlinedButton>
            <WhiteContainedButton
              onClick={() => {
                window.location.replace(stripCoreLink);
                // billingStore.setShowingPaymentModal(true);
              }}
            >
              Start Core Subscription
            </WhiteContainedButton>

            <WhiteContainedButton
              onClick={() => {
                window.location.replace(stripProLink);
                // billingStore.setShowingPaymentModal(true);
              }}
            >
              Start Pro Subscription
            </WhiteContainedButton>
          </>
        ) : null}
      </div>
      <Dialog
        open={!!billingStore.showingPaymentModal}
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        onSubmit={handleClose}
        aria-labelledby="modal-title"
      >
        <PaymentModal />
      </Dialog>
    </StripeProvider>
  );
});

const useStyles = makeStyles({ name: 'BillingSection' })((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 'calc(100vh - 300px)',

    '@media screen and (max-width: 800px), screen and (max-height: 800px)': {
      height: 'auto',
    },
    display: 'flex',
    flexDirection: 'column',

    '> *': {
      margin: '10px 0',
    },
  },
  titleSpace: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  icon: {
    width: '50px',
    height: 'auto',
  },
}));
